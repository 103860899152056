import {BusinessUnitResponse} from '@infrastructure/http/custom-setting/business-units/business-unit.response';
import {UserResponse} from '@infrastructure/http/organization/user/user.response';
import {IdName} from '@infrastructure/id-name';
import {UserCodeName} from '@infrastructure/user-code-name';
import {isNil} from 'lodash-es';
import {CustomerCategoryResponse} from '../customer-category/customer-category.response';
import {ErpCustomerOverviewResponse} from '../erp-customer/response/erp-customer-overview.response';
import {RegistrationContactPerson} from '../registration/registration-contact-person';
import {CustomerAddress} from './customer-address';

export class CustomerResponse {
  constructor(data: any = null) {
    if (!isNil(data)) {
      this.id = data.id;
      this.code = data.code;
      this.mainContactPerson = RegistrationContactPerson.fromJSON(data.mainContactPerson);
      this.companyName = data.companyName;
      this.customerAttribute = IdName.fromJSON(data.customerAttribute);
      this.customerCategory = CustomerCategoryResponse.fromJSON(data.customerCategory);
      this.enterpriseNature = IdName.fromJSON(data.enterpriseNature);
      this.enterpriseScale = data.enterpriseScale;
      this.industry = IdName.fromJSON(data.industry);
      this.area = IdName.fromJSON(data.area);
      this.businessLicenseFileName = data.businessLicenseFileName;
      this.addresses = (data.addresses || []).map((address: any) => CustomerAddress.fromJSON(address));
      this.status = IdName.fromJSON(data.status);
      this.companyRelationType = IdName.fromJSON(data.companyRelationType);
      this.parentCompanyId = data.parentCompanyId;
      this.createdAt = data.createdAt;
      this.modifiedBy = UserCodeName.fromJSON(data.modifiedBy);
      this.modifiedAt = data.modifiedAt;
      this.submittedAt = data.submittedAt;
      this.submittedBy = data.submittedBy;
      this.verifiedAt = data.verifiedAt;
      this.verifiedBy = data.verifiedBy;
      this.inChargeBy = data.inChargeBy;
      this.formId = data.formId;
      this.remark = data.remark;
      this.isOEMCustomer = data.isOEMCustomer;
      this.taxpayerIdentificationNumber = data.taxpayerIdentificationNumber;
      this.salesUsers = (data.salesUsers || []).map((user: any) => UserResponse.fromJSON(user));
      this.subCompanyIds = data.subCompanyIds;
      this.erpCustomers = data.erpCustomers;
      this.businessUnit = !isNil(data.businessUnit) ? BusinessUnitResponse.fromJSON(data.businessUnit) : null;
      this.sharedCustomerInformationIds = data.sharedCustomerInformationIds;
      this.discount = data.discount;
      this.discountStartDate = data.discountStartDate;
      this.discountEndDate = data.discountEndDate;
      this.priceAdjustmentRate = data.priceAdjustmentRate;
    }
  }

  id: number;
  code: string;
  mainContactPerson: RegistrationContactPerson;
  companyName: string;
  customerAttribute: IdName;
  customerCategory: CustomerCategoryResponse;
  enterpriseNature: IdName;
  enterpriseScale: string;
  industry: IdName;
  area: IdName;
  businessLicenseFileName: string;
  addresses: CustomerAddress[] = [];
  status: IdName;
  companyRelationType: IdName;
  parentCompanyId: number;
  createdAt: Date;
  modifiedBy: UserCodeName;
  modifiedAt: Date;
  submittedAt: Date;
  submittedBy: string;
  verifiedAt: Date;
  verifiedBy: string;
  inChargeBy: string;
  formId: string;
  remark: string;
  isOEMCustomer: boolean;
  taxpayerIdentificationNumber: string;
  salesUsers: UserResponse[] = [];
  subCompanyIds: number[] = [];
  erpCustomers: ErpCustomerOverviewResponse[] = [];
  businessUnit: BusinessUnitResponse;
  sharedCustomerInformationIds: number[] = [];
  discount: string;
  discountStartDate: Date;
  discountEndDate: Date;
  priceAdjustmentRate: string;

  public static fromJSON(data: any): CustomerResponse {
    const object = new CustomerResponse(data);

    return object;
  }
}
