import {Status} from '@domain/status.enum';
import {AccountSetResponse} from '@infrastructure/account-set.response';
import {isNil} from 'lodash-es';

export class CustomerCategoryResponse {
  public readonly id: number | null = null;
  public readonly name: string | null = null;
  public readonly description: string | null = null;
  public readonly status: Status | null = null;
  public readonly accountSets: AccountSetResponse[] | null = null;
  public readonly customerCategoryName: string | null = null;

  public constructor(data: any = null) {
    if (!isNil(data)) {
      Object.assign(this, data);
      this.status = data.isActive ? Status.ACTIVE : Status.INACTIVE;
    }
  }

  public static fromJSON(data: any): CustomerCategoryResponse {
    return new CustomerCategoryResponse(data);
  }
}
